<template>
  <div class="select" @click="toggleArea">
    <label class="select__label" :for="id">{{ label }}</label>
    <div class="select__area"
         :class="[{
           'select__area_active' : areaExpanded,
           'select__area_disabled' : ! areaExpanded,
         }, ...selectClassList ?? []]"
         :id="id"
    >
      {{ selectedOption.display }}
    </div>
      <div class="select__options-box" v-show="areaExpanded">
        <div class="select__option" v-for="option in options" :key="option.key" @click="chooseValue(option)">
          {{ option.display }}
        </div>
      </div>
    <span class="select__close-icon" v-show="enableErase && selectedOption.value !== null" @click.stop="erase(id)"></span>
  </div>
</template>

<script>
export default {
  name: 'AppSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    selectClassList: Array,
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    enableErase: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update', 'erase', 'focus'],
  data() {
    return {
      areaExpanded: false,
      selectedOption: {
        value: null,
        display: ''
      }
    }
  },
  methods: {
    toggleArea(event) {
      if (this.disabled) {
        return
      }

      this.areaExpanded = ! this.areaExpanded
      this.$emit('focus', event)
    },

    chooseValue(option) {
      this.selectedOption = option
      this.$emit('update', {
        value: option.value,
        field: this.$props.id
      })
    },

    erase(id) {
      this.selectedOption = {
        value: null,
        display: ''
      }

      this.$emit('erase', id)
    }
  },
  watch: {

  },
  created() {
    for (const option of this.$props.options) {
      if (Object.prototype.hasOwnProperty.call(option, 'selected') && option.selected) {
        this.selectedOption = option
        break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.select {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.select__label {
  position: absolute;
  top: -6px;
  left: 20px;
  padding: 0 10px;
  font-size: 10px;
  color: $label-text-color;
  background-color: #fff;
  z-index: 99;
}

.select__area {
  width: 100%;
  height: 50px;
  padding: 0 40px 0 20px;
  line-height: 50px;

  &_active {
    border-top: 1px solid $separator-color;
    border-left: 1px solid $separator-color;
    border-right: 1px solid $separator-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &_disabled {
    border: 1px solid $separator-color;
    border-radius: $border-radius;
  }
}

.select__options-box {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid $separator-color;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  z-index: 99;
}

.select__option {
  height: 50px;
  padding: 0 40px 0 20px;
  line-height: 50px;

  &:hover {
    background-color: $page-background;
  }

  &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.select__close-icon {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  transform: translateY(100%);

  &::before, &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border: 0.5px solid $separator-color;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

@media screen and (min-width: 576px) {
  .select__label {
    font-size: 12px;
  }

  .select__area, .select__option {
    height: 55px;
    line-height: 55px;
  }

  .select__area {
    font-size: 15px;
  }

  .select__options-box {
    top: 55px;
  }

  .select__close-icon {
    top: 2px;
    width: 17px;
    height: 17px;
  }
}

@media screen and (min-width: 768px) {
  .select__area, .select__option {
    height: 68px;
    line-height: 68px;
  }

  .select__options-box {
    top: 68px;
  }

  .select__close-icon {
    top: 8px;
    width: 18px;
    height: 18px;
  }
}

@media screen and (min-width: 992px) {
  .select__label {
    font-size: 13px;
  }

  .select__area {
    font-size: 16px;
  }
}

</style>