import { request, parse } from '@/utils/request'

const list = async (token) => {
  const response = await request.get('/roles', {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

export { list }