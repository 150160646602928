<template>
  <wrapper>
    <app-header/>
    <div class="users">
      <container class="users__form-container">
        <div class="users__form-skeleton skeleton" v-if="! rolesDownloaded">
          <div class="users__form-skeleton-item skeleton-element"></div>
          <div class="users__form-skeleton-item skeleton-element"></div>
        </div>
        <form class="users__form form-search" v-if="rolesDownloaded" v-show="form === 'search'">
          <div class="users__form-header-container">
            <div class="users__form-header container-header">
              Поиск
            </div>
            <div class="users__form-navigation form-search__navigation" @click.stop="form = 'create'; errorBag = {};">
              Новый пользователь
            </div>
          </div>
          <div class="users__form-content">
            <div class="users__form-element-box users__form-element-username">
              <app-input
                  class="users__form-input"  id="search" label="Фамилия Имя Отчество" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('search') }]"
                  v-model:model-value="filter.search"
                  @erase="clearFilter" @focus="clearError"
              />
              <span class="users__form-error-description form-caption-error" v-show="fieldHasError('search')" v-html="errorBag.search"></span>
            </div>
            <div class="users__form-element-box users__form-element-email">
              <app-input
                  class="users__form-input"  id="email" label="E-mail" type="email" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('email') }]"
                  v-model:model-value="filter.email"
                  @erase="clearFilter" @focus="clearError"
              />
              <span class="users__form-error-description form-caption-error" v-show="fieldHasError('email')" v-html="errorBag.email"></span>
            </div>
            <div class="users__form-element-box users__form-element-login">
              <app-input
                  class="users__form-input"  id="login" label="Логин" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('login') }]"
                  v-model:model-value="filter.login"
                  @erase="clearFilter" @focus="clearError"
              />
              <span class="users__form-error-description form-caption-error" v-show="fieldHasError('login')" v-html="errorBag.login"></span>
            </div>
            <div class="users__form-element-box users__form-search-element-roles">
              <app-select
                  class="users__form-input" id="role_ids" label="Роль" enable-erase
                  :options="roles" v-model:model-value="filter.role_ids"
                  :select-class-list="[{ 'form-element-error' : fieldHasError('role_ids') }]"
                  @erase="() => { filter.role_ids = null }" @update="(event) => { filter.role_ids = event.value }" @focus="clearError"
              />
              <span class="form-caption-error" v-show="fieldHasError('role_ids')" v-html="errorBag.role_ids"></span>
            </div>
            <app-button class="users__form-button" button-style="button_primary" is-submit @click.prevent="listUsers">
              Поиск
            </app-button>
            <app-button class="users__form-button" button-style="button_cancel" @click="eraseFilter">
              Сбросить
            </app-button>
          </div>
        </form>
        <form class="users__form form-create" v-if="rolesDownloaded" v-show="form === 'create'">
          <div class="users__form-header-container">
            <div class="users__form-header container-header">
              Новый пользователь
            </div>
            <div class="users__form-navigation form-create__navigation" @click.stop="form = 'search'; errorBag = {};">
              Фильтры поиска
            </div>
          </div>
          <div class="users__form-content">
            <div class="users__form-element-box users__form-element-lastname">
              <app-input
                  class="users__form-input"  id="lastName" label="Фамилия" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('last_name') }]"
                  v-model:model-value="lastName"
                  @erase="clearData" @focus="clearError"
              />
              <span class="users__form-error-description form-caption-error" v-show="fieldHasError('last_name')" v-html="errorBag.last_name"></span>
            </div>
            <div class="users__form-element-box users__form-element-firstname">
              <app-input
                  class="users__form-input"  id="firstName" label="Имя" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('first_name') }]"
                  v-model:model-value="firstName"
                  @erase="clearData" @focus="clearError"
              />
              <span class="users__form-error-description form-caption-error" v-show="fieldHasError('first_name')" v-html="errorBag.first_name"></span>
            </div>
            <div class="users__form-element-box users__form-element-patronymic">
              <app-input
                  class="users__form-input"  id="patronymic" label="Отчество" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('patronymic') }]"
                  v-model:model-value="patronymic"
                  @erase="clearData" @focus="clearError"
              />
              <span class="users__form-error-description form-caption-error" v-show="fieldHasError('patronymic')" v-html="errorBag.patronymic"></span>
            </div>
            <div class="users__form-element-box users__form-element-login">
              <app-input
                  class="users__form-input"  id="login" label="Логин" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('login') }]"
                  v-model:model-value="login"
                  @erase="clearData" @focus="clearError"
              />
              <span class="users__form-error-description form-caption-error" v-show="fieldHasError('login')" v-html="errorBag.login"></span>
            </div>
            <div class="users__form-element-box users__form-element-email">
              <app-input
                  class="users__form-input"  id="password" label="Пароль" type="password" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('password') }]"
                  v-model:model-value="password"
                  @erase="clearData" @focus="clearError"
              />
              <span class="users__form-error-description form-caption-error" v-show="fieldHasError('password')" v-html="errorBag.password"></span>
            </div>
            <div class="users__form-element-box users__form-create-element-roles">
              <app-select
                  class="users__form-input" id="roleId" label="Роль" enable-erase
                  :options="roles" v-model:model-value="roleId"
                  :select-class-list="[{ 'form-element-error' : fieldHasError('role_id') }]"
                  @erase="() => { roleId = null }" @update="(event) => { roleId = event.value }" @focus="clearError"
              />
              <span class="form-caption-error" v-show="fieldHasError('role_id')" v-html="errorBag.role_id"></span>
            </div>
            <app-button class="users__form-button" button-style="button_primary" is-submit @click.prevent="createUser">
              Создать
            </app-button>
            <app-button class="users__form-button" button-style="button_cancel" @click="eraseData">
              Сбросить
            </app-button>
          </div>
        </form>
      </container>
      <container class="users__table-container">
        <div class="users__table-skeleton skeleton" v-show="! usersDownloaded">
          <div class="users__table-skeleton-item-wrapper">
            <div class="users__table-skeleton-header skeleton-element"></div>
          </div>
          <div class="users__table-skeleton-item-wrapper">
            <div class="users__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="users__table-skeleton-item-wrapper">
            <div class="users__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="users__table-skeleton-item-wrapper">
            <div class="users__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="users__table-skeleton-item-wrapper">
            <div class="users__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="users__table-skeleton-paginator-wrapper">
            <div class="users__table-skeleton-paginator skeleton-element"></div>
          </div>
        </div>
        <div class="users__table-wrapper" v-show="usersDownloaded">
          <app-table class="users__table">
            <app-thead :headers="headers" @sort="sortUsers"/>
            <app-tr v-for="user in users" :key="user.id">
              <app-td>
                <app-th>
                  Сотрудник
                </app-th>
                <app-tt class="users__table-cell-username">
                  <img :src="getAvatar(user.avatar)" alt="Аватар" class="users__avatar">
                  <span class="users__name" v-html="user.name"></span>
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Название проекта
                </app-th>
                <app-tt>
                  {{ user.project }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Роль
                </app-th>
                <app-tt>
                  {{ user.role }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Логин
                </app-th>
                <app-tt>
                  {{ user.login }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  E-mail
                </app-th>
                <app-tt>
                  {{ user.email }}
                </app-tt>
              </app-td>
              <app-tb>
                <app-l type="danger" :path="$route" v-if="user.roleSlug !== 'super_admin'" @click.prevent="showModal(user.id)">
                  Удалить
                </app-l>
                <app-l type="primary" :path="
                  {
                    name: 'EditUser',
                    params: {
                      userId: user.id
                    }
                  }
                  "
                  v-if="user.id !== parseInt($store.state.user.profile.id)"
                  >
                  Подробнее
                </app-l>
              </app-tb>
            </app-tr>
          </app-table>
          <app-table-paginator url-name="Users" v-model:paginator="paginator"/>
        </div>
      </container>
    </div>
  </wrapper>
  <modal-form
      caption="Удаление пользователя"
      message="Вы уверены, что хотите удалить данного пользователя? После удаления пользователя его восстановление станет невозможным"
      :confirm="deleteUser" :cancel="closeModal"  :data="modalData" v-show="modalShown"
  />
</template>

<script>
import Wrapper from '@/layouts/Wrapper'
import Container from '@/layouts/Container'
import AppHeader from '@/components/AppHeader'
import AppInput from '@/components/AppInput'
import AppSelect from '@/components/AppSelect'
import AppButton from '@/components/AppButton'
import AppTable from '@/components/AppTable'
import AppTableHeader from '@/components/AppTableHeader'
import AppTableRow from '@/components/AppTableRow'
import AppTableTextHeader from '@/components/AppTableTextHeader'
import AppTableColumn from '@/components/AppTableColumn'
import AppTableButtonColumn from '@/components/AppTableButtonColumn'
import AppTableText from '@/components/AppTableText'
import AppTableLink from '@/components/AppTableLink'
import AppTablePaginator from '@/components/AppTablePaginator'
import ModalForm from '@/components/ModalForm'
import { list as listUsers, create, destroy } from '@/api/users'
import { list as listRoles } from '@/api/roles'
import { isError } from '@/utils/request'
import { camelToSnake } from '@/utils/helpers'
import { deserialize } from 'deserialize-json-api'

export default {
  name: 'Users',
  components: {
    Wrapper,
    Container,
    AppHeader,
    AppInput,
    AppSelect,
    AppButton,
    AppTable,
    AppThead: AppTableHeader,
    AppTr: AppTableRow,
    AppTh: AppTableTextHeader,
    AppTd: AppTableColumn,
    AppTb: AppTableButtonColumn,
    AppTt: AppTableText,
    AppL: AppTableLink,
    AppTablePaginator,
    ModalForm
  },
  data() {
    return {
      headers: [
        {
          field: 'last_name',
          display: 'Сотрудник'
        },
        {
          field: 'project_id',
          display: 'Проект'
        },
        {
          field: 'role_id',
          display: 'Роль'
        },
        {
          field: 'login',
          display: 'Логин'
        },
        {
          field: 'email',
          display: 'E-mail'
        },
        {
          field: null,
          display: ''
        }
      ],
      lastName: '',
      firstName: '',
      patronymic: '',
      password: '',
      login: '',
      roleId: null,
      roles: [],
      rolesDownloaded: false,
      users: [],
      usersDownloaded: false,
      form: 'search',
      userName: '',
      filter: {},
      sort: null,
      paginator: {
        total: undefined,
        count: undefined,
        current_page: 1,
        per_page: 100,
        total_pages: undefined
      },
      errorBag: {},
      modalShown: false,
      modalData: {}
    }
  },
  methods: {
    getAvatar(path) {
      return path || require('@/assets/svg/avatar.svg')
    },

    getQueryParams() {
      const search = new URLSearchParams(window.location.search)
      const query = {}

      if (search.has('page')) {
        this.paginator.current_page = parseInt(search.get('page'))
      } else {
        query.page = 1
      }

      if (search.has('per_page')) {
        this.paginator.per_page = parseInt(search.get('per_page'))
      } else {
        query.per_page = 100
      }

      this.sort = search.has('sort') ? search.get('sort') : null

      for (const [key, value] of search.entries()) {
        query[key] = value
      }

      if (Object.keys(query).length !== 0) {
        this.$router.replace({ query })
      }
    },

    async listUsers() {
      this.errorBag = {}
      this.usersDownloaded = false

      const query = {
        page: this.paginator.current_page,
        per_page: this.paginator.per_page
      }

      if (this.sort !== null) {
        query.sort = this.sort
      }

      if (Object.keys(this.filter).length !== 0) {
        query.filter = {}

        for (const [key, value] of Object.entries(this.filter)) {
          if (value !== '' && value !== null) {
            query.filter[key] = value
          }
        }
      }

      const response = await listUsers(this.$store.state.user.token, query, ['project'])

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('filter.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        }

        this.usersDownloaded = true
        return
      }

      this.users = []

      deserialize(response.data).data.forEach((user) => {
        this.users.push({
          id: parseInt(user.id),
          avatar: user.avatar,
          name: `${user.last_name} <br class="display-992">${user.first_name} <br class="display-992">${user.patronymic ?? ''}`,
          project: Object.prototype.hasOwnProperty.call(user, 'project') ? user.project.name : 'Не указано',
          email: user.email ?? 'Не указано',
          login: user.login,
          role: user.role.name,
          roleSlug: user.role.slug
        })
      })

      this.paginator = response.data.meta.pagination
      this.usersDownloaded = true
    },

    async createUser() {
      this.errorBag = {}

      const response = await create(this.$store.state.user.token, {
        role_id: this.roleId,
        login: this.login,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
        patronymic: this.patronymic
      })

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('data.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        } else {
          this.$notify({
            type: 'error',
            text: response.data.errors.title
          })
        }
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.listUsers()
      }

      this.login = ''
      this.password = ''
      this.firstName = ''
      this.lastName = ''
      this.patronymic = ''
    },

    async deleteUser(data) {
      const response = await destroy(this.$store.state.user.token, data.id)

      if (isError(response.status)) {
        this.$notify({
          type: 'error',
          text: response.data.errors.title
        })
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.listUsers()
      }

      this.modalData = {}
      this.closeModal()
    },

    async downloadRoles() {
      const response = await listRoles(this.$store.state.user.token)

      if (isError(response.status)) {
        return
      }

      deserialize(response.data).data.forEach((role) => {
        this.roles.push({
          key: parseInt(role.id),
          value: parseInt(role.id),
          display: role.name
        })
      })

      this.rolesDownloaded = true
    },

    showModal(id) {
      this.modalData.id = id
      this.modalShown = true
    },

    closeModal() {
      this.modalShown = false
    },

    sortUsers(field) {
      const query = {
        page: this.paginator.current_page,
        per_page: this.paginator.per_page
      }

      if (field !== null) {
        query.sort = field
      }

      this.sort = field

      this.$router.replace({ query })
      this.listUsers()
    },

    clearFilter(field) {
      delete this.filter[field]
    },

    clearData(field) {
      this[field] = ''
    },

    eraseFilter() {
      this.filter = {}
    },

    eraseData() {
      this.lastName = ''
      this.firstName = ''
      this.patronymic = ''
      this.email = ''
      this.login = ''
    },

    fieldHasError(field) {
      return Object.prototype.hasOwnProperty.call(this.errorBag, field)
    },

    clearError(event) {
      const field = camelToSnake(event.target.id)

      if (Object.prototype.hasOwnProperty.call(this.errorBag, field)) {
        delete this.errorBag[field]
      }
    }
  },
  computed: {
    currentPage() {
      return this.$route.query.page
    }
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (oldPage !== undefined && newPage !== oldPage) {
        this.paginator.current_page = newPage
        this.listUsers()
      }
    }
  },
  created() {
    this.getQueryParams()
    this.downloadRoles()
    this.listUsers()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.users__form-container {
  margin-bottom: 14px;
  padding: 25px 20px 20px;
}

.users__form-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.users__form-navigation {
  position: relative;
  font-size: 12px;
  color: $primary-links-color;
  cursor: pointer;

  &::before, &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 6px;
    border-bottom: 1px solid $primary-links-color;
  }
}

.form-search__navigation {
  padding-right: 12px;

  &::before {
    top: 5.5px;
    right: 0;
    transform: rotate(45deg);
  }

  &::after {
    top: 9px;
    right: 0;
    transform: rotate(-45deg);
  }
}

.form-create__navigation {
  padding-left: 12px;

  &::before {
    top: 9px;
    left: 0;
    transform: rotate(45deg);
  }

  &::after {
    top: 5.5px;
    left: 0;
    transform: rotate(-45deg);
  }
}

.users__form-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.users__form-element-box {
  flex-basis: 100%;
}

.users__form-input {
  width: 100%;
}

.users__form-error-description {
  display: block;
  margin-top: 10px;
}

.users__form-button {
  flex-basis: calc(50% - 10px);
}

.users__avatar {
  display: none;
}

/* skeleton */
.users__form-skeleton {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.users__form-skeleton-item {
  width: 100%;
  height: 68px;
}

.users__table-skeleton {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
}

.users__table-skeleton-item-wrapper, .users__table-skeleton-paginator-wrapper {
  padding: 15px 20px;
}

.users__table-skeleton-item-wrapper {
  border-bottom: 1px solid $border-color;
}

.users__table-skeleton-header {
  width: 70%;
  height: 23px;
}

.users__table-skeleton-item {
  width: 100%;
  height: 200px;
}

.users__table-skeleton-paginator {
  width: 100%;
  height: 23px;
}

@media screen and (min-width: 576px) {
  .users__form-container {
    margin-bottom: 28px;
    padding: 25px 20px 30px;
  }

  .users__form-navigation {
    font-size: 14px;
  }

  .form-search__navigation {
    &::before {
      top: 7.5px;
    }

    &::after {
      top: 11px;
    }
  }

  .form-create__navigation {
    &::before {
      top: 11px;
    }

    &::after {
      top: 7.5px;
    }
  }

  .users__form-header-container {
    margin-bottom: 25px;
  }

  .users__form-content {
    gap: 30px 20px;
  }

  .users__form-element-firstname, .users__form-element-patronymic {
    flex-basis: calc(50% - 10px);
  }

  .users__form-element-email, .users__form-element-login, .users__form-search-element-roles, .users__form-create-element-roles {
    flex-basis: calc(50% - 10px);
  }

  .users__form-button {
    flex-basis: calc(25% - 15px);
  }

  .users__avatar {
    display: inline;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: $border-radius;
  }

  /* skeleton */
  .users__form-skeleton {
    gap: 30px;
  }

  .users__table-skeleton-paginator {
    width: 288px;
    height: 23px;
  }
}

@media screen and (min-width: 768px) {
  .users__form-container {
    padding: 25px 20px 35px;
  }

  .users__form-navigation {
    font-size: 15px;
  }

  .form-search__navigation {
    &::before {
      top: 8px;
    }

    &::after {
      top: 11.5px;
    }
  }

  .form-create__navigation {
    &::before {
      top: 11.5px;
    }

    &::after {
      top: 8px;
    }
  }

  .users__form-content {
    gap: 30px;
  }

  .users__form-element-lastname, .users__form-element-firstname, .users__form-element-patronymic {
    flex-basis: calc(100% / 3 - 60px / 3);
  }

  .users__form-element-email, .users__form-element-login, .users__form-search-element-roles, .users__form-create-element-roles {
    flex-basis: calc(50% - 15px);
  }

  .users__form-button {
    flex-basis: calc(25% - 45px / 2);
  }
}

@media screen and (min-width: 992px) {
  .users__form-container {
    padding: 25px 30px 30px;
  }

  .users__avatar {
    position: static;
  }

  .users__table-cell-username {
    display: flex;
    gap: 20px;
  }

  /* skeleton */
  .users__table-skeleton-item-wrapper, .users__table-skeleton-paginator-wrapper {
    padding: 15px 30px;
  }

  .users__table-skeleton-item {
    height: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .users__form-container {
    padding: 35px 40px 40px;
  }

  .users__form-element-lastname, .users__form-element-firstname, .users__form-element-patronymic {
    flex-basis: calc(percentage(257.5 / 1030) - 90px / 4);
  }

  .users__form-element-username {
    flex-basis: calc(percentage(736 / 1090) - 15px);
    order: 1;
  }

  .users__form-search-element-roles {
    flex-basis: calc(percentage(354 / 1090) - 15px);
    order: 2;
  }

  .users__form-create-element-roles {
    flex-basis: calc(percentage(257.5 / 1030) - 90px / 4);
    order: 2;
  }

  .users__form-element-login {
    flex-basis: calc(percentage(353 / 1030) - 90px / 4);
    order: 3;
  }

  .users__form-element-email {
    flex-basis: calc(percentage(353 / 1030) - 90px / 4);
    order: 4;
  }

  .users__form-button {
    flex-basis: calc(percentage(162 / 1030) - 90px / 4);
    order: 5;
  }

  /* skeleton */
  .users__table-skeleton-item-wrapper, .users__table-skeleton-paginator-wrapper {
    padding: 15px 40px;
  }
}
</style>

<style>
.display-992 {
  display: none;
}

@media screen and (min-width: 992px) {
  .display-992 {
    display: inline;
  }
}
</style>