import { request, parse } from '@/utils/request'

const list = async (token, query, include) => {
  if (include !== undefined) {
    query.include = include.join(',')
  }

  const response = await request.get('/users', {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      ...query
    }
  })

  return parse(response)
}

const get = async (token, id, include) => {
  const response = await request.get('/users/' + id, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      include: include ? include.join(',') : null
    }
  })

  return parse(response)
}

const create = async (token, data) => {
  const response = await request.post('/users', { data }, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const update = async (token, id, data) => {
  const response = await request.patch('/users/' + id, { data }, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const destroy = async (token, id) => {
  const response = await request.delete('/users/' + id, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const attachProject = async (token, userId, projectId) => {
  const response = await request.post(`/users/${userId}/relationships/projects/${projectId}`, {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const detachProject = async (token, userId) => {
  const response = await request.delete(`/users/${userId}/relationships/projects`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const count = async (token, projectId, status, day) => {
  const response = await request.get('/users/count', {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      project_id: projectId,
      status: status,
      day
    }
  })

  return parse(response)
}

export { list, get, create, update, destroy, attachProject, detachProject, count }